<template>
    <vs-sidebar position-right parent="body" default-index="1" color="primary" class="sidebarx" spacer v-model="sidebarActive">
        <div>
            <h4 class="text-xl px-6 mt-4 mb-4"> Activity Logs </h4>
            <div class="flex flex-col space-y-3">
                <template v-if="activity_logs">
                    <div v-html="activity_logs" class="list" /> 
                </template>
                <template v-else>
                    <span class="text-gray-600 px-6">No log entries.</span>
                </template>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
export default {
  name: "LogsSidebar",
  data() {
    return {
      sidebarActive: false
    };
  },
  props: {
    activity_logs: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeSidebar() {
      this.sidebarActive = false;
    },
    openSidebar() {
      this.sidebarActive = true;
    }
  }
};
</script>


<style lang="scss">
    .list {
        height: 90vh;
        overflow-y: auto;
        
        strong {
          text-transform: capitalize;
          font-weight: 400;
        }

        em {
          font-style: normal;
        }

        > span {    
          font-size: 0.9rem;
          line-height: 1.5;
          padding: 1rem 1.5rem;
          display: block;

          > strong,
          > span {
              color: black;
              font-weight: 500;
              word-break: break-all;
          }

          > span:last-of-type {
              display: block;
              margin-top: .6rem;
              font-weight: 400;
              font-size: 0.8rem;
              color: #7f7f7f;
          }
        }
        > span:not(:last-of-type) {
        border-bottom: 1px solid #f4f4f4 !important;
        }
    }
  </style>