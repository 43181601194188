<template>
  <vs-card class="p-2">
    <div
      slot:header
      class="flex flex-no-wrap w-full justify-end items-center"
      vs-w="12"
    >
      <!-- <div class="mr-2">
        <vs-button v-if="canCreate" @click="redirectToCreateOrg"
          >Create</vs-button
        >
      </div> -->
      <!-- <div
              class="mr-2"
              v-if="
                $store.state.AppActiveUser.userType === 'superAdmin' ||
                ($store.state.AppActiveUser.userType === 'admin' &&
                  canCreate &&
                  !franchiseId)
              "
            >
              <vs-button
                @click="onboardNurseHandler"
                icon-pack="feather"
                icon="icon-edit"
                >Onboard Nurse</vs-button
              >
            </div> -->
    </div>
    <div style="padding-left: 10px">
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
            <!-- <div class="pl-1" style="width: 14.5rem">
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div> -->
            <div class="w-24 pl-1">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text relative"
                style="border: 1px solid #e8e8e8"
              >
                <div>
                  <div>
                    <h4 class="mr-2 mobile-text">
                      Clinic Name:
                      {{
                        users[indextr].shippingDetails.companyName
                          ? users[indextr].shippingDetails.companyName
                          : "N/A" | capitalize
                      }}
                    </h4>
                  </div>
                  <div>
                    <h4 class="mr-2 mobile-text">Order ID:</h4>
                    <div
                      v-for="item in users[indextr].supplierOrders"
                      v-bind:key="item"
                    >
                      <h4 class="mr-2 mobile-text">
                        {{ item.order.orderNumber }}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <h4 class="mobile-text">
                      Cost $AUD: ${{ (users[indextr].total / 100).toFixed(2) }}
                    </h4>
                  </div>
                </div>
                <div class="mobile-text">
                  <p>
                    Date:
                    {{ moment(users[indextr].createdAt).format("DD MMM YYYY") }}
                  </p>
                </div>
                <div class="mobile-text">
                  <p>Status: {{ getStatus(users[indextr]) | capitalize }}</p>
                </div>
                <div class="absolute top-0 right-0 p-1">
                  <a
                    :href="getPdf(users[indextr])"
                    :download="getName(users[indextr])"
                    target="blank"
                  >
                    <vs-button
                      type="flat"
                      size="small"
                      color="success"
                      class="m-1"
                      >Invoice PDF</vs-button
                    >
                  </a>
                  <vs-button
                    size="small"
                    type="border"
                    @click="viewOrderDetails(users[indextr]._id)"
                    >View</vs-button
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div slot="header" class="flex flex-wrap flex-grow items-center">
            <div class="pb-5">
              <vs-select
                class="mr-6"
                placeholder="Select Year"
                autocomplete
                v-model="dataTableParams.year"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in yearDropdown"
                />
              </vs-select>
            </div>
            <div class="pb-5">
              <vs-select
                class="mr-6"
                autocomplete
                v-model="dataTableParams.month"
              >
                <vs-select-item
                  v-for="(item, index) in monthDropdown"
                  :key="item + '' + index"
                  :value="item.value"
                  :text="item.name"
                />
              </vs-select>
            </div>
            <div class="pb-5">
              <vs-select
                style="position: relative"
                placeholder="Select Clinic"
                autocomplete
                v-model="dataTableParams.clinic"
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>

            <div class="pb-5">
              <vs-select
                v-model="dataTableParams.status"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in statusFilter"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="clinic_name">Clinic Name</vs-th>
            <vs-th sort-key="date">Date</vs-th>
            <vs-th sort-key="order_id">Order ID</vs-th>
            <vs-th sort-key="cost_aud">Cost $AUD</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].shippingDetails.companyName">
                {{
                  data[indextr].shippingDetails.companyName
                    ? data[indextr].shippingDetails.companyName
                    : "NA"
                }}
              </vs-td>
              <vs-td :data="data[indextr].createdAt">
                {{ moment(data[indextr].createdAt).format("DD MMM YYYY") }}
              </vs-td>
              <vs-td :data="data[indextr].supplierOrders">
                <div v-if="data[indextr].supplierOrders.length > 0">
                  <div
                    v-for="item in data[indextr].supplierOrders"
                    v-bind:key="item"
                  >
                    {{ item.order.orderNumber }}
                  </div>
                </div>
              </vs-td>
              <vs-td :data="data[indextr].total">
                ${{ data[indextr].total | formatDecimals }}
              </vs-td>
              <vs-td :data="data[indextr].status">
                {{ getStatus(data[indextr]) }}
              </vs-td>
              <vs-td :data="data[indextr]._id">
                <div class="flex items-center">
                  <a
                    :href="getPdf(data[indextr])"
                    :download="getName(data[indextr])"
                    target="blank"
                  >
                    <vs-button
                      type="flat"
                      size="small"
                      color="success"
                      class="m-1"
                      >Invoice PDF</vs-button
                    >
                  </a>
                  <vs-button
                    size="small"
                    type="border"
                    @click="viewOrderDetails(data[indextr]._id)"
                    >View</vs-button
                  >
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "organization-table",
  components: {},
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminNurseDetailView",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clinics: [{ clinicId: null, clinicName: "All Clinics" }],
      monthDropdown: [
        { name: "All Months", value: null },
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
      ],
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      yearDropdown: [],
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        page: 1,
        limit: 25,
        year: "",
        month: null,
        clinic: null,
        status: "",
        id: null,
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      statusFilter: [
        { value: "", label: "All Status" },
        { value: "Paid", label: "Paid" },
        { value: "On hold", label: "Processing" },
        { value: "Failed", label: "Failed" },
        { value: "Payment-Failed", label: "Payment-Failed" },
      ],
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationOrders",
      "fetchOrganizationClinics",
    ]),
    getYearDropdown() {
      this.yearDropdown = [];
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    getStatus(item) {
      if (item.paymentType === "ClinicBankAccount") {
        if (item.status === "On hold") return "Processing";
        else if (item.status === "Failed") return "Failed";
        else if (item.status === "Payment-Failed") return "Payment-Failed";
        else if (
          item.status === "Paid" &&
          item.trackingNumbers &&
          item.trackingNumbers.length
        )
          return "Sent";
        else return "Paid";
      } else {
        if (item.trackingNumbers && item.trackingNumbers.length) return "Sent";
        else return "Paid";
      }
    },
    getPdf(row) {
      if (row.orderinvoices) {
        return row.orderinvoices.pdfLink;
      }
      return "#";
    },
    getName(row) {
      const companyName = row.shippingDetails.companyName
        ? row.shippingDetails.companyName
        : "NA";

      return `${row.orderinvoices.invoiceNumber} ${companyName}`;
    },
    viewOrderDetails(id) {
      this.$router.push(`orders/${id}`);
    },
    async getNursesList() {
      this.$vs.loading();
      console.log(this.dataTableParams);
      const res = await this.fetchOrganizationOrders(this.dataTableParams);
      this.$vs.loading.close();
      this.noDataText = "No Orders Available";
      this.serverResponded = true;
      this.users = res.data.data.docs;
      this.totalDocs =
        res.data.data.pagination.total || res.data.data.pagination[0].total;
      this.page =
        res.data.data.pagination.page || res.data.data.pagination[0].page;
      this.currentPage =
        res.data.data.pagination.page || res.data.data.pagination[0].page;
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNursesList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNursesList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNursesList();
    },
    redirectToCreateOrg() {
      this.$router.push("organizations/create");
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNursesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNursesList();
      }
    },
    "dataTableParams.year": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.year = newfilterVal;
        this.getNursesList();
      }
    },
    "dataTableParams.month": function (newfilterVal, oldFilterVal) {
      console.log(newfilterVal, oldFilterVal);
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.month = newfilterVal === 13 ? null : newfilterVal;
        this.getNursesList();
      }
    },
    "dataTableParams.clinic": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newfilterVal;
        this.getNursesList();
      }
    },
    "dataTableParams.status": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.status = newfilterVal;
        this.getNursesList();
      }
    },
  },
  async created() {
    const res = await this.fetchOrganizationClinics({
      id: this.$route.params.organizationId,
    });
    res.data.data.docs.map((clinic) => {
      this.clinics.push({
        clinicId: clinic._id,
        clinicName: clinic.clinicName,
      });
    });
    this.getYearDropdown();
    // this.selectedMonth = this.monthDropdown[new Date().getMonth()];
    this.dataTableParams.id = this.$route.params.organizationId;
    // this.dataTableParams.activeClinicId = await this.$store.state
    //   .AppActiveClinicId;
    // if (this.franchiseId && this.franchiseId !== "") {
    //   this.dataTableParams.franchiseId = this.franchiseId;
    // }
    this.getNursesList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
  margin: 0.25rem;
}
</style>
