<template>
  <div class="pb-32 mt-4">
    <vs-card>
      <div slot="header" class="flex justify-between items-center">
        <div slot="header" class="flex items-center justify-end space-x-2 w-full">
          <vs-button @click="getLogs" class="flex-1 lg:flex-none bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
          <vs-button @click="updateOrganization">Save Details</vs-button>
        </div>
      </div>
      <div>
        <form autocomplete="off">
            <h6 class="mb-3">Company Info</h6>
            <div class="grid grid-cols-2 gap-4">
              <div class="w-full">
                <label for="name"> Organisation Name </label>
                <vs-input 
                  :success="!errors.first('name') && organizationData.name != ''" 
                  val-icon-success="done" 
                  val-icon-danger="error" 
                  v-validate="'required|max:150'" 
                  name="name"
                  data-vv-as="Organisation Name" 
                  icon-no-border icon="icon icon-user"
                  icon-pack="feather" 
                  class="w-full" 
                  v-model="organizationData.name" 
                />
              </div>
              <div class="w-full" v-if="$isAuRegion()">
                <label for="name"> ABN </label>
                <vs-input 
                  :success="!errors.first('abn') && organizationData.abn != ''" 
                  val-icon-success="done"
                  val-icon-danger="error" 
                  v-validate="'required|max:150'" 
                  name="abn" 
                  data-vv-as="ABN" 
                  icon-no-border icon="icon icon-user" 
                  icon-pack="feather" 
                  class="w-full"
                  v-model="organizationData.abn" 
                />
              </div>
            </div>

            <h6 class="mb-2 mt-8">Account Manager</h6>
            <div class="grid grid-cols-2 gap-4">
                <vs-select 
                  name="accountManagerId" 
                  placeholder="Select a manager" 
                  class="w-full"
                  autocomplete 
                  data-vv-as="Organisation Account Manager" 
                  v-model="organizationData.accountManagerId"
                  v-validate="'required'" 
                  :success="!errors.first('accountManagerId') && organizationData.accountManagerId != ''" 
                  val-icon-success="done"
                  val-icon-danger="error" 
                >
                    <vs-select-item v-for="(item, index) in managers" :key="index" :value="item._id" :text="item.name" />
                </vs-select>
            </div>

            <h6 class="mb-2 mt-8">Account Owner</h6>
            <div class="w-full">
              <OrganisationOwnerDetails />
            </div>

            <h6 class="mb-2 mt-8">Sage Details</h6>
            <div class="grid grid-cols-2 gap-4">
              <div class="w-full">
                <label> Client ID </label>
                <vs-input
                  loading :success-text="sageSuccess" :success="sageSuccess ? true : false" :danger-text="sageError"
                  :danger="sageError ? true : false" val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="sageClientId" data-vv-as="Sage Client ID"  icon-no-border icon-pack="feather"
                  class="w-full" @input="validateSageCustomer" v-model="organizationData.sageClientId"
                />
              </div>
            </div>
        </form>
      </div>
    </vs-card>
    <LogsSidebar :activity_logs="activityLogs" ref="logsSidebar" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios.js";
import _ from "lodash";
import { updatedFieldChecker } from "../../../utils/updatedFieldChecker";
import OrganisationOwnerDetails from "./organizationOwnerDetails.vue";
import LogsSidebar from "@/views/components/account-creation-logs/logs.vue";

export default {
  components: {
    OrganisationOwnerDetails,
    LogsSidebar
  },
  data: () => ({
    organizationData: {
      name: "",
      abn: "",
      monthTreatmentInvoiceEmail: [],
      consultationFee: 0,
      isMonthlyComplianceEnabled: false,
      sageClientId: "",
      accountManagerId: ""
    },
    oldData: null,
    orgId: null,
    sageError:'',
    sageSuccess:'',
    managers: [],
    activityLogs: null,
    updater: null,
  }),
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationDetails",
      "updateOrganizationDetails",
      "fetchOwnerDetails",
    ]),
    ...mapActions("appUser", [
      "fetchAllAccountManagerDetails",
      "fetchAccountManagerDetails",
      "getAccountCreationLogs",
    ]),

    async getOrganizationDetails(id) {
      try {
        this.$vs.loading();
        const res = await this.fetchOrganizationDetails(id);
        this.$vs.loading.close();
        this.organizationData = {
          name: res.data.data.name,
          abn: res.data.data.abn,
          monthTreatmentInvoiceEmail: res.data.data.monthTreatmentInvoiceEmail,
          consultationFee: res.data.data.consultationFee,
          isMonthlyComplianceEnabled: res.data.data.isMonthlyComplianceEnabled,
          isDrugbookEnabled: res.data.data.isDrugbookEnabled,
          isAnalyticsEnabled: res.data.data.isAnalyticsEnabled,
          isInventoryEnabled: res.data.data.isInventoryEnabled,
          isByo:res.data.data.isByo,
          isPaid: res.data.data.isPaid,
          isTestAccount: res.data.data.isTestAccount || false,
          sageClientId: res.data.data.sageClientId,
          accountManagerId: res.data.data.accountManagerId ? res.data.data.accountManagerId : "",
          isContourOrg: res.data.data.isContourOrg || false,
        };
        this.oldData = _.cloneDeep(this.organizationData)
        this.getSageCustomerInfo()
      } catch (error) {
        console.log(error);
      }
    },
    isEnabled(type) {
      return this.organizationData[type] ? this.organizationData[type] : false;
    },
    async updateOrganization() {
      let isValid = await this.$validator.validate();

      if (isValid) {
        this.$vs.loading();

        let data = {
          name: this.organizationData.name,
          abn: this.organizationData.abn,
          monthTreatmentInvoiceEmail:
            this.organizationData.monthTreatmentInvoiceEmail,
          consultationFee: this.organizationData.consultationFee
            ? this.organizationData.consultationFee
            : 0,
          isMonthlyComplianceEnabled: this.isEnabled("isMonthlyComplianceEnabled"),
          isDrugbookEnabled: this.isEnabled("isDrugbookEnabled"),
          isAnalyticsEnabled: this.isEnabled("isAnalyticsEnabled"),
          isPaid: this.isEnabled("isPaid"),
          isTestAccount: this.isEnabled("isTestAccount"),
          isInventoryEnabled: this.isEnabled("isInventoryEnabled"),
          sageClientId: this.organizationData.sageClientId,
          isByo: this.isEnabled("isByo"), 
          isContourOrg: this.isEnabled("isContourOrg"),
          accountManagerId: this.organizationData.accountManagerId ? this.organizationData.accountManagerId : null
        };

      let newData = {
        ...data,
        updater: `${this.updater.firstName} ${this.updater.lastName}`,
        name: this.organizationData.name,
        updatedFields: updatedFieldChecker(this.oldData, this.organizationData)
      };

      if (this.oldData.accountManagerId !== this.organizationData.accountManagerId) {
        const oldManager = this.oldData.accountManagerId ? await this.getAccountManager(this.oldData.accountManagerId) : null; 
        const newManager = await this.getAccountManager(this.organizationData.accountManagerId);

        newData = {
          ...newData,
          updatedFields: {
            ...newData.updatedFields,
            accountManagerId: {
              oldValue: oldManager && oldManager[0] ? oldManager[0].name : '',
              newValue: newManager && newManager[0] ? newManager[0].name : ''
            }
          }
        };
      }

        try {
          const res = await this.updateOrganizationDetails({
            data: newData,
            id: this.$route.params.organizationId,
          });

          this.$vs.notify({
            title: "Details Updated",
            text: "Organisation Details successfully updated.",
            color: "success",
          });
          this.getOrganizationDetails(this.$route.params.organizationId);
          this.$vs.loading.close();
          
        } catch (err) {
          this.$vs.loading.close();
          isValid = false;
          if (err.status === 422) {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update details. Try again!",
              color: "danger",
            });
          }
          if (err.status === 409) {
            this.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          }
        }
      }
    },
    async getSageCustomerInfo() {
        try {
            if (!this.organizationData.sageClientId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/sage/customer/${this.organizationData.sageClientId}`)

            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Customer ID ${this.organizationData.sageClientId} corresponds to an entity named ${data.data.NAME} with status ${data.data.STATUS} on the Sage platform.`
                this.sageError = ''
            } else {
                this.sageSuccess = ''
                this.sageError = `The customer with ID ${this.organizationData.sageClientId} does not exist on the Sage platform.`
            }
        } catch (e) {
            this.sageSuccess = ''
            this.sageError = `The customer with ID ${this.organizationData.sageClientId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    },
    async validateSageCustomer() {
        if (this.timeout)
            clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
            this.getSageCustomerInfo()
        }, 1000); // delay
    },
    async getAccountManagers() {
      this.fetchAllAccountManagerDetails()
        .then(res => {
          this.managers = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getAccountManager(id) {
      return this.fetchAccountManagerDetails(id);
    },
    async getLogs() {
      this.$refs.logsSidebar.openSidebar();

      await this.getAccountCreationLogs({
        id: this.$route.params.organizationId, 
        type: 'organization'
      })
        .then(res => {
         this.activityLogs = res

        }).catch(err => {
          console.warn(err)
        })
    },
    removeAccountManager() {
      this.organizationData.accountManagerId = ""
    }
  },
  async created() {
    this.getOrganizationDetails(this.$route.params.organizationId);
    this.getAccountManagers();
    this.updater = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    this.orgId = this.$route.params.organizationId;
  },
};
</script>


<style lang="scss">
  .sidebarx {
    .vs-sidebar {
      max-width: 400px;

      &--items {
        overflow-y: auto;
        overflow-x: hidden !important;
      }
    }
  }
</style>