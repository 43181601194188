var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-32"},[_c('vs-card',[_c('div',{staticClass:"flex justify-between items-center",attrs:{"slot":"header"},slot:"header"},[_c('h4',[_vm._v(" Organisation Settings")]),_c('vs-button',{on:{"click":_vm.updateOrganization}},[_vm._v("Save")])],1),_c('div',[_c('div',{staticClass:"px-5"},[_c('p',[_vm._v(" Organisation settings will act as the default settings for all clinics created unless updated manually. ")])]),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',[_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('monthTreatmentInvoiceEmail') &&
                _vm.organizationData.monthTreatmentInvoiceEmail != '',"val-icon-success":"done","val-icon-danger":"error","name":"monthTreatmentInvoiceEmail","data-vv-as":"Monthly Treatment Invoice Email(s)","label":"Monthly Treatment Invoice Email"},model:{value:(_vm.organizationData.monthTreatmentInvoiceEmail),callback:function ($$v) {_vm.$set(_vm.organizationData, "monthTreatmentInvoiceEmail", $$v)},expression:"organizationData.monthTreatmentInvoiceEmail"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('consultationFee') &&
                _vm.organizationData.consultationFee != '',"val-icon-success":"done","val-icon-danger":"error","name":"consultationFee","data-vv-as":"Treatment Consultation Fee(Default is $25)","label":"Treatment Consultation Fee(Default is $25)"},model:{value:(_vm.organizationData.consultationFee),callback:function ($$v) {_vm.$set(_vm.organizationData, "consultationFee", $$v)},expression:"organizationData.consultationFee"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('closedLoopConsultationFee') &&
                _vm.organizationData.closedLoopConsultationFee != '',"val-icon-success":"done","val-icon-danger":"error","name":"closedLoopConsultationFee","data-vv-as":"closedLoopConsultationFee","label":"Closed looped doctors Fee","type":"number"},model:{value:(_vm.organizationData.closedLoopConsultationFee),callback:function ($$v) {_vm.$set(_vm.organizationData, "closedLoopConsultationFee", $$v)},expression:"organizationData.closedLoopConsultationFee"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Monthly Compliance")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isMonthlyComplianceEnabled') &&
                _vm.organizationData.isMonthlyComplianceEnabled != '',"val-icon-success":"done","val-icon-danger":"error","name":"consultationFee","data-vv-as":"Enable Monthly Compliance","label":"Enable/Disable Monthly Compliance"},model:{value:(_vm.organizationData.isMonthlyComplianceEnabled),callback:function ($$v) {_vm.$set(_vm.organizationData, "isMonthlyComplianceEnabled", $$v)},expression:"organizationData.isMonthlyComplianceEnabled"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Drugbook")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isDrugbookEnabled') &&
                _vm.organizationData.isDrugbookEnabled != '',"val-icon-success":"done","val-icon-danger":"error","name":"consultationFee","data-vv-as":"Enable Monthly Compliance","label":"Enable/Disable Drugbook"},model:{value:(_vm.organizationData.isDrugbookEnabled),callback:function ($$v) {_vm.$set(_vm.organizationData, "isDrugbookEnabled", $$v)},expression:"organizationData.isDrugbookEnabled"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Insights ")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isAnalyticsEnabled') &&
                _vm.organizationData.isAnalyticsEnabled != '',"val-icon-success":"done","val-icon-danger":"error","name":"analytics","data-vv-as":"Enable Analytics","label":"Enable/Disable Insights"},model:{value:(_vm.organizationData.isAnalyticsEnabled),callback:function ($$v) {_vm.$set(_vm.organizationData, "isAnalyticsEnabled", $$v)},expression:"organizationData.isAnalyticsEnabled"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Inventory ")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isInventoryEnabled') &&
                _vm.organizationData.isInventoryEnabled != '',"val-icon-success":"done","val-icon-danger":"error","name":"analytics","data-vv-as":"Enable Analytics","label":"Enable/Disable Insights"},model:{value:(_vm.organizationData.isInventoryEnabled),callback:function ($$v) {_vm.$set(_vm.organizationData, "isInventoryEnabled", $$v)},expression:"organizationData.isInventoryEnabled"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Paid Version")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isPaid') &&
                _vm.organizationData.isPaid != '',"val-icon-success":"done","val-icon-danger":"error","name":"consultationFee","data-vv-as":"Enable Monthly Compliance","label":"Enable/Disable Paid Version"},model:{value:(_vm.organizationData.isPaid),callback:function ($$v) {_vm.$set(_vm.organizationData, "isPaid", $$v)},expression:"organizationData.isPaid"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Test Account")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isPaid') &&
                _vm.organizationData.isTestAccount != '',"val-icon-success":"done","val-icon-danger":"error","name":"isTestAccount","data-vv-as":"Test Account","label":"Enable/Disable Test Account"},model:{value:(_vm.organizationData.isTestAccount),callback:function ($$v) {_vm.$set(_vm.organizationData, "isTestAccount", $$v)},expression:"organizationData.isTestAccount"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Skip Monthly Payment")]),_c('vs-switch',{attrs:{"name":"isSkipMonthlyPayment","label":"Enable/Disable Skip Monthly Payment"},model:{value:(_vm.organizationData.isSkipMonthlyPayment),callback:function ($$v) {_vm.$set(_vm.organizationData, "isSkipMonthlyPayment", $$v)},expression:"organizationData.isSkipMonthlyPayment"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable BYO Organization")]),_c('vs-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"success":!_vm.errors.first('isBYO') &&
                _vm.organizationData.isByo != '',"val-icon-success":"done","val-icon-danger":"error","name":"isByo","data-vv-as":"BYO Organization","label":"Enable/Disable BYO Organization"},model:{value:(_vm.organizationData.isByo),callback:function ($$v) {_vm.$set(_vm.organizationData, "isByo", $$v)},expression:"organizationData.isByo"}})],1)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"w-full px-5 my-5"},[_c('label',[_vm._v("Enable/Disable Contour Organization")]),_c('vs-switch',{attrs:{"name":"isContourOrg","label":"Enable/Disable Contour Organiztion"},model:{value:(_vm.organizationData.isContourOrg),callback:function ($$v) {_vm.$set(_vm.organizationData, "isContourOrg", $$v)},expression:"organizationData.isContourOrg"}})],1)])])])])]),_c('vs-popup',{staticClass:"holamundo",attrs:{"title":"Confirmation required","active":_vm.showModal},on:{"update:active":function($event){_vm.showModal=$event}}},[_c('p',[_vm._v("Are you sure you want to turn Drugbook off? There are "+_vm._s(_vm.clinics.length)+" clinics within your organisation currently using Drugbook.")]),_c('ul',{staticStyle:{"list-style":"disc","margin-left":"30px","margin-top":"10px"}},_vm._l((_vm.clinics),function(name,index){return _c('li',{key:index},[_vm._v(_vm._s(name))])}),0),_c('vs-divider'),_c('vs-row',{attrs:{"vs-align":"space-between","vs-justify":"flex-end","vs-type":"flex","vs-w":"12"}},[_c('vs-col',{staticStyle:{"display":"block","text-align":"right"},attrs:{"vs-w":"3"}},[_c('vs-button',{staticStyle:{"padding":"5px 15px","margin-right":"5px"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Confirm")])],1),_c('vs-col',{staticStyle:{"display":"block","text-align":"right"},attrs:{"vs-w":"2"}},[_c('vs-button',{staticStyle:{"padding":"4px 15px"},attrs:{"type":"border"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }